var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"-12px","width":"100vw"}},[_c('top-nav',{attrs:{"title":"Nueva Orden"}}),_c('v-card',{staticStyle:{"height":"100vh"},attrs:{"flat":"","tile":"","color":"grey lighten-5"}},[_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-dialog',{ref:"orderDate",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"txt-middle",attrs:{"label":"Fecha de la orden","outlined":"","background-color":"white","append-icon":"mdi-calendar","readonly":"","rules":[_vm.rules.required]},model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.orderDateDialog),callback:function ($$v) {_vm.orderDateDialog=$$v},expression:"orderDateDialog"}},[_c('v-card',[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.orderDateDialog = false}},model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}})],1)],1),_c('v-autocomplete',{attrs:{"items":_vm.customers,"label":"Cliente","item-text":_vm.getCustomerText,"item-value":"id","outlined":"","background-color":"white","rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attr,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.email))])],1)],1)]}}]),model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('v-select',{attrs:{"items":_vm.products,"label":"Producto","outlined":"","background-color":"white","rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attr,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.toMoney( item.price )))])],1)],1)]}}]),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}),_c('v-select',{attrs:{"items":_vm.paymentMethods,"label":"Método de pago","item-text":"text","item-value":"value","outlined":"","background-color":"white","rules":[_vm.rules.required]},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}}),_c('v-text-field',{attrs:{"label":"Confirmación de pago","outlined":"","background-color":"white","rules":[_vm.rules.required]},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})],1),_c('v-spacer'),_c('v-divider',{staticClass:"my-4"}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"px-0 pb-0 grey--text",attrs:{"cols":"6"}},[_vm._v("Subtotal")]),_c('v-col',{staticClass:"text-right px-0 pb-0 grey--text",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.getCartSubtotal()))]),_c('v-col',{staticClass:"px-0 grey--text",attrs:{"cols":"6"}},[_vm._v("Tax")]),_c('v-col',{staticClass:"text-right px-0 grey--text",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.getCartTax()))]),_c('v-col',{staticClass:"px-0 title grey--text text--darken-2",attrs:{"cols":"6"}},[_vm._v("Total")]),_c('v-col',{staticClass:"text-right  px-0 title grey--text text--darken-2",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.getCartTotal()))])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticStyle:{"width":"calc(100vw - 16px)"},attrs:{"depressed":"","bottom":"","fixed":"","color":"primary","loading":_vm.processing},on:{"click":_vm.createOrder}},[_vm._v("Crear orden")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }