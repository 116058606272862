/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-06-18
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * customer subscriptions in the Petu App
 * 
 */

import { _st } from '../softech';
import { createCustomerSubscription, updateCustomerSubscription } from '../graphql/mutations';
import { getCustomerSubscription, listCustomerSubscriptions } from '../graphql/queries';
import { getSubscriptionByCustomer, getExpiredSubscriptions } from '@/graphql/customQueries';
import { graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { API } from '@/inc/api';
import { PetuProduct } from './product';
import { SubscriptionFrequency } from '../models';
import { PetuOrder } from './order';
import { PetuCustomer } from './customer';

export class PetuSubscription {
    constructor(subscription = null) {
        if(_st.isNU(subscription))
            this.data = {
                id                  : '',
                dateCreated         : null,
                autoRenew           : true,
                nextPaymentDate     : null,
                productID           : '',
                customerID          : '',
                orderID             : '',
                remainingSessions   : 0,
                lastRenewal         : null 
            };
        else
            this.data = subscription;

        // helpers
        
    }

    async load(subscriptionId = null) {
        if(_st.isEmpty(subscriptionId)) {
            console.error('Subscription ID cannot be empty.');
            return;
        }

        // load product from DB
        const c = await API.graphql(graphqlOperation(getCustomerSubscription, { id: subscriptionId }));
        this.data = c.data.getCustomerSubscription;
    }
    async loadFromCustomerId(customerId = null) {
        
        if(_st.isEmpty(customerId)) {
            console.error('Customer ID cannot be empty.');
            return;
        }

        // load cart from DB
        const p = await API.graphql(graphqlOperation(listCustomerSubscriptions, { filter: { customerID: { eq: customerId } } }));

        if(p.data.listCustomerSubscriptions.items.length > 0)
            this.data = p.data.listCustomerSubscriptions.items[0];
    }

    async getList({ filter, limit }) {
        try {
            let variables = {};
            if(!_st.isNUE(filter)) variables.filter = filter;
            if(!_st.isNUE(limit)) variables.limit = limit;

            let subscriptions = null;
            if(_st.isNU(variables))
                subscriptions = await API.graphql(graphqlOperation(listCustomerSubscriptions));
            else
                subscriptions = await API.graphql(graphqlOperation(listCustomerSubscriptions, variables));

            return subscriptions.data.listCustomerSubscriptions.items;
        }
        catch(error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    async getListByCustomer({ customerID, filter, limit}) {
        try {
            let variables = {};
            if(!_st.isNUE(customerID)) variables.customerID = customerID;
            if(!_st.isNUE(filter)) variables.filter = filter;
            if(!_st.isNUE(limit)) variables.limit = limit;

            let subscriptions = null;
            if(_st.isNUE(variables))
                subscriptions = await API.graphql(graphqlOperation(getSubscriptionByCustomer));
            else
                subscriptions = await API.graphql(graphqlOperation(getSubscriptionByCustomer, variables));

            return subscriptions.data.subscriptionByCustomer.items;
        }
        catch(error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    async getAllExpiredSubscriptions() {
        try {
            let subscriptions = await API.graphql(graphqlOperation(getExpiredSubscriptions, { today: moment().format('YYYY-MM-DDTHH:mm:ssS') }));
            return subscriptions.data.listCustomerSubscriptions.items;
        }
        catch(error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    async save() {
        const now = this.now();

        // delete properties that are not allowed on the update
        delete this.data.createdAt;
        delete this.data.updatedAt;
        delete this.data.product;
        
        try {
            if (_st.isNUE(this.data.id)) {
                this.data.dateCreated = now
                this.data.id = uuidv4()

                const p = await API.graphql(graphqlOperation(createCustomerSubscription, { input: this.data }))
                this.data = p.data.createCustomerSubscription
            } else {
                await API.graphql(graphqlOperation(updateCustomerSubscription, { input: this.data }))
            }

            return Promise.resolve('Success')
        } catch (error) {
            console.error('Error saving subscription', error)
            return Promise.reject(error)
        }
    }

    async renew() {
        try {
            if(_st.isNUE(this.data.productID))
                return Promise.reject('Product ID is missing')
            if(_st.isNUE(this.data.customerID))
                return Promise.reject('Customer ID is missing')

            // get product
            let product = new PetuProduct()
            await product.load(this.data.productID)

            if(!product.isPurchasable())
                return Promise.reject('Product is not purchasable')

            let interval = 'months';
            if(product.data.subscriptionFrequency == SubscriptionFrequency.WEEKLY)
                interval = 'weeks';
            else if(product.data.subscriptionFrequency == SubscriptionFrequency.ANNUALLY)
                interval = 'years';

            // create new order
            let order = new PetuOrder()
            order.data.customerID = this.data.customerID;
            order.data.products.push({ productId: this.data.productID, qty: 1 });
            order.data.fromSubscriptionID = this.data.id;
            order.data.subtotal = product.getPrice();
            order.data.discounts = 0;
            order.data.shipping = 0;
            order.data.tax = product.getPrice() * 0.115; // CAMBIAR EL TAX HARDCODED POR UNA VARIABLE CONFIGURABLE
            order.data.total = product.getPrice() + order.data.tax;

            // retain remaining sessions
            const customer = new PetuCustomer()
            await customer.load(this.data.customerID)
            const subscriptions = await customer.getSubscriptions()
            const subscription = subscriptions.find((s) => s.productID === this.data.productID)

            this.data.nextPaymentDate       = moment().add(1, interval);
            this.data.lastRenewal           = this.now()
            this.data.remainingSessions     = product.data.subscriptionSessions

            if (subscription && _st.isInteger(subscription.remainingSessions) && this.isInsideGracePeriod(subscription.nextPaymentDate)) {
                this.data.remainingSessions += subscription.remainingSessions
            }
            
            await order.renewSubscription()
            this.data.orderID = order.data.id
            await this.save()
        } 
        catch(error) {
            console.error('Error renewing subscription', error)
            return Promise.reject(error)
        }
    }

    isInsideGracePeriod(date) {
        return new Date(date) > Date.now() - 1209600000
    }

    updateAutoRenew( val ) {
        try {
            let api = new API();
            let res = api.post('/subscription/auto-renew', {
                subscriptionId  : this.data.id,
                autoRenew       : val
            });

            if( res.data.status !== true ) {
                return Promise.reject( res.data.message );
            }

            this.data = res.data.data;
            return Promise.resolve();
        }
        catch(error) {
            console.log('Error changing autorenew option', error);
            return Promise.reject(error);
        }
    }

    now() {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(Date.now() - tzoffset)).toISOString(); // => '2015-01-26T06:40:36.181'
    }
}