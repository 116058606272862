<template>
    <div style="margin:-12px;width:100vw;">
        <!-- app bar -->
        <top-nav title="Nueva Orden">
        </top-nav>

        <!-- body -->
        <v-card flat tile color="grey lighten-5" style="height:100vh;">
            <v-card-text>
                <v-form ref="form">
                    <v-dialog ref="orderDate" v-model="orderDateDialog" width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="orderDate" label="Fecha de la orden" outlined background-color="white" class="txt-middle" v-bind="attrs"
                                v-on="on" append-icon="mdi-calendar" readonly :rules="[rules.required]"></v-text-field>
                        </template>

                        <v-card>
                            <v-date-picker v-model="orderDate" no-title @input="orderDateDialog = false" scrollable></v-date-picker>
                        </v-card>
                    </v-dialog>

                    <v-autocomplete v-model="customer" :items="customers" label="Cliente" :item-text="getCustomerText" item-value="id" 
                        outlined background-color="white" :rules=[rules.required]>
                        <template v-slot:selection="{ item }">
                            <span>{{ item.firstName }} {{ item.lastName }}</span>
                        </template>
                        <template v-slot:item="{item, on, attr}">
                            <v-list-item v-on="on" v-bind="attr">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.firstName }} {{ item.lastName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <v-select v-model="product" :items="products" label="Producto" outlined background-color="white" :rules=[rules.required]>
                        <template v-slot:selection="{ item }">
                            <span>{{ item.name }}</span>
                        </template>
                        <template v-slot:item="{item, on, attr}">
                            <v-list-item v-on="on" v-bind="attr">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ toMoney( item.price ) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                    <v-select v-model="paymentMethod" :items="paymentMethods" label="Método de pago" item-text="text" item-value="value" 
                        outlined background-color="white" :rules=[rules.required]></v-select>
                    <v-text-field v-model="confirmation" label="Confirmación de pago" outlined background-color="white" :rules=[rules.required]></v-text-field>
                </v-form>

                <v-spacer></v-spacer>

                <v-divider class="my-4"></v-divider>

                <v-container fluid>
                    <v-row class="mx-2">
                        <v-col cols="6" class="px-0 pb-0 grey--text">Subtotal</v-col>
                        <v-col cols="6" class="text-right px-0 pb-0 grey--text">{{ getCartSubtotal() }}</v-col>

                        <!-- <v-col cols="6" class="px-0 pb-0 grey--text" v-if="couponVisible">Coupons</v-col>
                        <v-col cols="6" class="text-right px-0 pb-0 grey--text" v-if="couponVisible"> - {{ getCartDiscounts() }}</v-col> -->

                        <!-- <v-col cols="12" class="text-right px-0 pb-0" v-if="couponVisible">
                            <template v-for="(coupon, ix) in coupons">
                                <v-col cols="2" class="pt-0 pb-1">
                                    <v-btn icon small text color="red" @click="removeCoupon(coupon)"><v-icon small>mdi-close</v-icon></v-btn>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-1" align-self="center">
                                    <span class="awesome-mini-cart-drawer-text">{{ coupon.code }}</span>
                                </v-col>
                                <v-col cols="4" class="text-right pt-0 pb-1" align-self="center" style="border-right: 2px solid #cdcdcd;">
                                    <span class="awesome-mini-cart-drawer-text">- {{ formatMoney(coupon.amount) }}</span>
                                </v-col>
                            </template>
                        </v-col> -->

                        <v-col cols="6" class="px-0 grey--text">Tax</v-col>
                        <v-col cols="6" class="text-right px-0 grey--text">{{ getCartTax() }}</v-col>

                        <v-col cols="6" class="px-0 title grey--text text--darken-2">Total</v-col>
                        <v-col cols="6" class="text-right  px-0 title grey--text text--darken-2">{{ getCartTotal() }}</v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn depressed bottom fixed color="primary" style="width:calc(100vw - 16px);" @click="createOrder" :loading="processing">Crear orden</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import TopNav from '@/components/admin/TopNav.vue';
import { PetuProduct } from '@/classes/product.js';
import { PetuCustomer } from '@/classes/customer.js';
import { PetuOrder } from '@/classes/order.js';
import { PetuSubscription } from '@/classes/subscription.js';
import { OrderStatus, SubscriptionFrequency } from '@/models';
import { mapGetters } from 'vuex';
import { _st } from '@/softech';
import moment from 'moment';
export default {
    data: () => ({
        orderDateDialog     : false,
        orderDate           : null,
        products            : [],
        product             : null,
        customers           : [],
        customer            : null,
        paymentMethods      : [
            { text: 'Efectivo', value: 'cash' },
            { text: 'ATH movil', value: 'athmovil' },
            { text: 'Tarjeta de credito', value: 'stripe' }
        ],
        paymentMethod       : null,
        confirmation        : '',

        processing          : false,

        rules               : { 
            required      : v => !!v || 'requerido',
        },
    }),
    computed: {
        ...mapGetters({
            user        : 'auth/customer',
        }),
        subtotal() {
            if( _st.isNUE( this.product ) )
                return 0;

            return this.product.price;
        },
        tax() {
            if( _st.isNUE( this.product ) )
                return 0;

            return this.product.price * 0.115;
        },
        total() {
            if( _st.isNUE( this.product ) )
                return 0;

            return this.subtotal + this.tax;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {  
            let d = new Date();
            let month = String( d.getMonth() + 1 ).padStart( 2, '0' );
            let day = String( d.getDate() ).padStart( 2, '0' );
            this.orderDate = `${d.getFullYear()}-${month}-${day}`;
            this.products = await (new PetuProduct()).getList(); 
            this.customers = ( await (new PetuCustomer()).getListLimit() ).items;
        },
        async createOrder() {
            this.processing = true;
            try {
                if( !this.$refs.form.validate() )
                    return;

                const product = { 
                    productId     : this.product.id, 
                    qty           : 1,
                    price         : this.product.price,
                    tax           : this.product.price * 0.115,
                    total         : Number(this.product.price) + (this.product.price * 0.115),
                    isTaxable     : true,
                    name          : this.product.name,
                    type          : this.product.type,
                    trainingType  : this.product.trainingType,
                    dimensions    : { length: 0, width: 0, height: 0},
                    weight        : 0,
                    images        : this.product.images
                };

                let order = new PetuOrder()
                order.data = {
                    ...order.data,
                    status: OrderStatus.PROCESSING.toLowerCase(),
                    customerId: this.customer,
                    products: [ product ],
                    subtotal: product.price,
                    discounts: 0.00,
                    shipping: 0.00,
                    tax: product.tax,
                    total: product.total,
                    coupons: [],
                    paymentMethod: this.paymentMethod == 'cash' ? 'athmovil' : this.paymentMethod,
                    paymentConfirmation: this.confirmation,
                    fromSubscriptionID: '',
                }

                await order.save();
                // this.createSubscriptions(order);
                this.$root.$emit('alert', { status: 'success', message: `Órden ha sido creada` });
                this.$router.push('/secure/orders');
            } 
            catch(err) {
                console.error(err);
                this.$root.$emit('alert', { status: 'error', message: `Error al crear la órden` });
            } 
            finally {
                this.processing = false
            }
        },
        async createSubscriptions(order) {
            try {
                let customer = new PetuCustomer();
                await customer.load(this.customer);

                let subscriptions = await customer.getSubscriptions();

                if( !this.product.generateSubscription )
                    return;

                let found = subscriptions.find((s) => s.productID === this.product.id);
                let subscription = null;

                if( _st.isNUE( found ) ) {
                    subscription = new PetuSubscription();
                    subscription.data.autoRenew = false;
                    subscription.data.productID = this.product.id;
                    subscription.data.customerID = customer.data.id;
                    subscription.data.remainingSessions = this.product.subscriptionSessions;
                    subscription.data.orderID = order.data.id;
                } 
                else {
                    subscription = new PetuSubscription( found );
                    subscription.data.remainingSessions += this.product.subscriptionSessions;
                    subscription.data.orderID = order.data.id;
                    subscription.data.lastRenewal = moment();
                }

                if( this.product.subscriptionFrequency === SubscriptionFrequency.WEEKLY ) {
                    subscription.data.nextPaymentDate = moment(order.data.orderDate).add(1, 'w');
                } 
                else if( this.product.subscriptionFrequency === SubscriptionFrequency.ANNUALLY ) {
                    subscription.data.nextPaymentDate = moment(order.data.orderDate).add(1, 'y');
                } 
                else {
                    subscription.data.nextPaymentDate = moment(order.data.orderDate).add(1, 'M');
                }

                await subscription.save();
                this.$root.$emit('alert', { status: 'success', message: `Suscripción ha sido creada` });
            } catch(err) {
                console.error(err);
                this.$root.$emit('alert', { status: 'error', message: `Error al crear suscripción` });
            }
        },
        getCustomerText( item ) {
            return `${item.firstName} ${item.lastName} ${item.email}`;
        },
        getCartSubtotal() {
            return _st.toMoney( this.subtotal );
        },
        getCartTax() {
            return _st.toMoney( this.tax );
        },
        getCartTotal() {
            return _st.toMoney( this.total );
        },
        toMoney( v ) {
            return _st.toMoney( v );
        }
    },
    components: { TopNav }
}
</script>

<style lang="scss" scoped>

</style>